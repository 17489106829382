import React from 'react';
import FeatureTwoItem from './FeatureTwoItem';

import Section from './data';


const FeatureTwo = () => {
  return (
    <React.Fragment>

      <svg className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12" width="404" height="784" fill="none" viewBox="0 0 404 784">
        <defs>
          <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="784" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
      </svg>

      <div className="relative mt-12 sm:mt-16 lg:mt-24">
        <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="lg:col-start-2">
            <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
              {Section.title}
            </h4>
            <p className="mt-3 text-lg leading-7 text-gray-500">
              {Section.details}
            </p>

            <ul className="mt-10">
              {
                Section.items.map((item, index) => {
                  const padding = index !== 0 ? 'mt-10' : ''; 
                  return (
                    <li key={index} className={padding}>
                      <FeatureTwoItem item={item} />
                    </li>
                  )
                })
              }
            </ul>
          </div>

          <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
            <svg className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404">
              <defs>
                <pattern id="e80155a9-dfde-425a-b5ea-1f6fadd20131" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="784" height="404" fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
            </svg>
            <img className="relative mx-auto" width="400" src={Section.imgUrl} alt="" />
          </div>
        </div>
      </div>

    </React.Fragment>
  )
}


export default FeatureTwo;