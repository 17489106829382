import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import axios from 'axios';

import Analytics from '../Analytics';

import Exercises from './exercises/Exercises';
import Nav from './Nav';
import exerciseData from '../database/sessionBuilder';


class MinimumMovements extends Component {
  
  state = {
    selectedExercise: exerciseData[0],
    isLoading: true,
    exercises: []
  }

  componentDidMount() {
    this.loadMovements();
  }

  render() {
   
    return (
      <React.Fragment>
        {/* <Nav /> */}
        <Nav />
        <div className="py-10 bg-gray-100">
          <header>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="max-w-3xl mx-auto">
                <h1 className="text-3xl font-bold leading-tight text-gray-900 mb-10 pl-6">
                  Today's Minimum Movements
                </h1>
              </div>
            </div>
          </header>
          <main>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="max-w-3xl mx-auto">
                {
                  this.state.isLoading ? this.renderLoader() : this.renderExercises()
                }
              </div>
            </div>
          </main>
        </div>
      </React.Fragment>
    )
  }

  renderLoader() {
    return (
      <button type="button" className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
        Loading...
      </button>
    )
  }

  renderExercises = () => {
    return (
      <React.Fragment>
        <div style={{ width: '100%', height:'400px', backgroundColor: '#000' }} >
          {
            this.state.selectedExercise ? <ReactPlayer volume={0} width='100%' height='400px' url={this.state.selectedExercise.videoUrl} loop={true} playing={true} playsInline={true} /> : null
          }
        </div>        
        <Exercises selectedExercise={this.state.selectedExercise || { _id: '' }} onSelect={this.onSelect} data={this.state.exercises} />
      </React.Fragment>
    )
  }



  onSelect = selectedExercise => {
    this.setState({ selectedExercise });
    Analytics.playVideo();
  }


  loadMovements = () => {
    let baseUrl = 'https://dev-api.sevenseven.app';
    let url = `${baseUrl}/exercises/minimum-movements`;

    axios.get(url)
      .then(response => {
        this.setState({ exercises: response.data, selectedExercise: response.data[0], isLoading: false });
      })
      .catch(error => console.log(error));
  }


}

export default MinimumMovements;