import React, { Component } from 'react';

import CTAHeader from './CTAHeader';
import SubscriberForm from './SubscriberForm';
import Text from './data';


const CTA = () => {
    return (
      <div className="bg-gray-100">
        <div className="max-w-screen-xl mx-auto px-4 py-12 sm:px-6 lg:py-16 lg:px-8">
          <div className="px-6 py-6 bg-blue-700 rounded-lg md:py-12 md:px-12 lg:py-16 lg:px-16 xl:flex xl:items-center">
            <CTAHeader title={Text.title} details={Text.details} />
            <SubscriberForm buttonTitle={Text.buttonText} caption={Text.formCaption} successMessage={Text.subriberSuccessMessage} />
          </div>
        </div>
      </div>
    )
}


export default CTA;