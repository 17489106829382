
const Text = {
  title: 'Exercises emailed to your inbox daily',
  details: '3 week program to get you moving. Sign up to shape up.',
  buttonText: 'Let\'s Go',
  formCaption: '*If you love these workouts we\'ll send you a link at the end of your program to keep receiving them : )',
  subriberSuccessMessage: 'Congrats you\'re subscribed! We can\'t wait for your first session!'
}


export default Text;