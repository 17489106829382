import phones from '../../../assets/session.png';


const data = {
  title: 'Unique To You',
  details: 'Seven Seven tailors your program with minimum spend and maximum lifestyle convenience. It provides training sessions to your fitness needs and exercise preference. It allows you to get more from your lifestyle and training. ',
  imgUrl: phones,
  items: [
    {
      title: 'Minimum Movements: Unlock your Freedom of Movement',
      details: 'The Minimum Movements Program features seven daily exercises to encourage movement across your body. It is entry level fitness to teach members how to move well. Each day delivers different exercises to unlock your freedom of movement and help you move better throughout the day. This program is highly recommended for when you wake up in the morning or after you\'ve been sitting down for long periods of the day. ',
      isBeta: false
    },
    {
      title: 'Equipment Packs: Reach your Training Goals ',
      details: 'Equipment packs are designed to meet your training goals with minimum spend and maximum convenience. They\'re made to suit your training goals, preferences and lifestyle. Equipment packs allow members to prioritise their health while focusing on other areas of their life. They are our most popular product with seven seven members. ',
      isBeta: true
    }
  ]
}


export default data;