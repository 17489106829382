import React from 'react';



const FeatureTwoItem = ({ item }) => {
  return (
    <div className="flex">
      <div className="flex-shrink-0">
        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
        <svg  className="h-6 w-6"xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z" clipRule="evenodd" />
        </svg>
        </div>
      </div>
      <div className="ml-4">
        <h5 className="text-lg leading-6 font-medium text-gray-900">{item.title} {item.isBeta ? <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-blue-100 text-blue-800">
                       Beta
                    </span> : null}</h5>
        <p className="mt-2 text-base leading-6 text-gray-500">
        {item.details}
        </p>
      </div>
    </div>
  )
}

export default FeatureTwoItem;