import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './styles/tailwind.output.css';
import AppContainer from './components/AppContainer';

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <AppContainer />
      </BrowserRouter>
    </React.Fragment>
  )
}

export default App;
