

export const EquipmentType = {
  0: 'None',
  1: 'Bands',
  2: 'Dumbbells',
  3: 'Kettlebells',
  4: 'Weight Plate'
};

export const ProtocolType = {
  0: 'Stability & Control',
  1: 'Functional Strength',
  2: 'Cardiovascular Health',
  3: 'Aerobic Conditioning',
  4: 'Anaerobic Conditioning'
}

export const sessions = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16];


const duplicate = items => {
  return items.reduce(function (res, current, index, array) {
    return res.concat([current, current]);
  }, []);
}

const format = protocol => {
  return { ...protocol, loading: duplicate(protocol.loading) }
}



/// set by weeks
const stabilityLoading = {
  hint: 'Week 1-8: Perform each exercise with tempo of 4 seconds down, 2 second pause, and 4 seconds up. This tempo encourages full range of movement and joint strength and stability. Hold any side plank for 30 seconds each and front planks for 60 seconds each',
  loading: [
    '3 x 4 Reps',
    '3 x 5 Reps',
    '3 x 5 Reps',
    '3 x 6 Reps',
    '3 x 5 Reps',
    '3 x 6 Reps',
    '3 x 6 Reps',
    '3 x 7 Reps',
  ]
};

/// set by weeks
const functionalStrengthLoading = {
  hint: 'Week 1-8: Complete an exercise with small rest between sets. Have 60 seconds rest before completing next exercises. Increase weight and repeat loading cycle.',
  loading: [
    '3 x 8 Reps',
    '3 x 10 Reps',
    '3 x 10 Reps',
    '3 x 12 Reps',
    '3 x 10 Reps',
    '3 x 12 Reps',
    '3 x 12 Reps',
    '3 x 15 Reps',
  ]
};

/// set by weeks
const cardioLoading = {
  hint: 'Circuit: Complete 1 set on each exercise with minimal rest. Have 90 seconds rest. Repeat 2 more times.',
  loading: [
    '3 x 8 Reps',
    '3 x 10 Reps',
    '3 x 10 Reps',
    '3 x 12 Reps',
    '3 x 10 Reps',
    '3 x 12 Reps',
    '3 x 12 Reps',
    '3 x 15 Reps',
  ]
};

/// set by weeks
const aerobicLoading = {
  hint: 'Week 1-4: Complete 3 sets on one exercise before moving on. Have 60 seconds rest between exercises.\n\nWeek 5-8: Complete 6 sets on one exercise before moving on. Have 90 seconds rest between exercises',
  loading: [
    '30 seconds on 10 seconds off',
    '30 seconds on 10 seconds off',
    '30 seconds on 10 seconds off',
    '30 seconds on 10 seconds off',
    '30 seconds on 15 seconds off',
    '30 seconds on 15 seconds off',
    '30 seconds on 15 seconds off',
    '30 seconds on 15 seconds off',
  ]
};

/// set by weeks
const anaerobicLoading = {
  hint: 'Week 1-4: Complete 3 sets on one exercise before moving on. Have 90 seconds rest between exercises\n\nWeek 1-4: Complete 4 sets on one exercise before moving on. Have 120 seconds rest between exercises',
  loading: [
    '20 seconds on 10 seconds off',
    '20 seconds on 10 seconds off',
    '20 seconds on 10 seconds off',
    '20 seconds on 10 seconds off',
    '20 seconds on 10 seconds off',
    '20 seconds on 10 seconds off',
    '20 seconds on 10 seconds off',
    '20 seconds on 10 seconds off',
  ]
};


export const Loading = {
  0: format(stabilityLoading),
  1: format(functionalStrengthLoading),
  2: format(cardioLoading),
  3: format(aerobicLoading),
  4: format(anaerobicLoading),
};