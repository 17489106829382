import phones from '../../../assets/phones.png';



const Icons = {
  arrow: function() {
    return (
      <svg  className="h-6 w-6"xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z" clipRule="evenodd" />
      </svg>
    )
  },
  web: function() {
    return (
      <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
      </svg>
    )
  },
  scales: function() {
    return (
      <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
      </svg>
    )
  },
  power: function() {
    return (
      <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
      </svg>
    )
  }
}

const Text = {
  title: 'Your Framework',
  details: 'Training frameworks covering all essential components to healthy living. It\'s exercise prescription designed to support your lifestyle and change.',
  imgUrl: phones,
  items: [
    {
      name: 'Stability and Control: Learn to move well',
      details: 'There are 6 major bodyweight movements to learn how to control and stabilise your muscles and joints. Learn to maintain range of movement, joint positions and coordination from your central nervous system. This will help you move better throughout the day by limiting unwarranted movement.',
      icon: Icons.arrow
    },
    {
      name: 'Function Strength: Increase longevity as you age',
      details: 'Strength training improves activities of daily living. As you age, muscle mass and strength levels naturally decline. By maintaining a consistent strength training routine, it improves bone health, muscle mass, joint flexibility, weight control and balance. This increases your quality of life overtime.',
      icon: Icons.arrow
    },
    {
      name: 'Aerobic: Improve your heart, lunges and immune health ',
      details: 'Aerobic exercise improves your cardiovascular health. By developing your aerobic system, it increases your body\'s ability to regulate itself and stay healthy. Strong cardiovascular health means you\'re less likely to become sick and handle stress and anxiety better through endorphins. ',
      icon: Icons.arrow
    },
    {
      name: 'Anaerobic: Improve you metabolism and energy levels',
      details: 'Anaerobic exercise increases your body\'s ability to regulate itself and manage energy. It builds lean muscle which in return burns more calories and supports weight loss overtime. Anaerobic exercise is stressful on the body and needs adequate recovery to support healthy living. ',
      icon: Icons.arrow
    }
  ]
}

export default Text;