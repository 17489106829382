import React from 'react';


const sectionTitle = 'Your Week In Health';
const sectionDetails = 'Sessions adapted to your training routine. Possimus magnam voluptatum cupiditate veritatis in, accusamus quisquam.';


const SectionHeader = () => {
  return (
    <div className="relative">
      <h3 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
        {sectionTitle}
      </h3>
      <p className="mt-4 max-w-3xl mx-auto text-center text-xl leading-7 text-gray-500">
        {sectionDetails}
      </p>
    </div>
  )
};

export default SectionHeader;
