import React from 'react';
import FeatureOne from './featureOne/FeatureOne';
import FeatureTwo from './featureTwo/FeatureTwo';
import SectionHeader from './SectionHeader';



const Features = () => {
  return (
    <div className="py-16 bg-gray-100 overflow-hidden lg:py-24" id="features">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
        <svg className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4" width="404" height="784" fill="none" viewBox="0 0 404 784">
          <defs>
            <pattern id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="784" fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
        </svg>

        <SectionHeader />
        <FeatureOne />
        <FeatureTwo />
        
      </div>
    </div>
  )
}

export default Features;