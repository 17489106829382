import React from 'react';
import Exercise from './Exercise';

const Exercises = ({ data, onSelect, selectedExercise, isLegend }) => {
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md mt-4 md:mt-0">
      <ul>
        {
          data.map((exercise, index) => {
            let isSelected = exercise._id === selectedExercise._id;
            return (
              <li key={index} className="border-t border-gray-200">
                <Exercise isSelected={isSelected} exercise={exercise} onSelect={onSelect} isLegend={isLegend || false} />
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}

export default Exercises;