import React from 'react';


const CTAHeader = ({ title, details }) => {
  return (
    <div className="xl:w-0 xl:flex-1">
      <h2 className="text-2xl leading-8 font-extrabold tracking-tight text-white sm:text-3xl sm:leading-9">
        {title}
      </h2>
      <p className="mt-3 max-w-3xl text-lg leading-6 text-blue-200" id="newsletter-headline">
        {details}
      </p>
    </div>
  )
}



export default CTAHeader