import React from 'react';


const Item = ({data}) => {
  return (
    <li className="mt-10" >
      <div className="flex">
        <div className="flex-shrink-0">
          <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
            {data.icon()}
          </div>
        </div>
        <div className="ml-4">
          <h5 className="text-lg leading-6 font-medium text-gray-900">{data.name}</h5>
          <p className="mt-2 text-base leading-6 text-gray-500">
            {data.details}
          </p>
        </div>
      </div>
    </li>
  )
};


export default Item;