import React from 'react';


const EquipmentItem = ({ name }) => {
  return (
    <li>
      <div className="relative border rounded-tl-md rounded-tr-md p-4 flex flex-col md:pl-4 md:pr-6 md:grid md:grid-cols-3">
        <label className="flex items-center text-sm leading-5 space-x-3 cursor-pointer">
          <input onChange={() => console.log('check')} name="pricing_plan" type="radio" className="form-radio h-4 w-4 text-blue-500 transition duration-150 ease-in-out cursor-pointer" aria-describedby="plan-option-pricing-0 plan-option-limit-0"/>
          <span className="font-medium text-gray-900">{name}</span>
        </label>
        <p id="plan-option-pricing-0" className="ml-6 pl-1 text-sm leading-5 md:ml-0 md:pl-0 md:text-center">
          <span className="font-medium">$40 / month</span>
          <span> ($360 / yr)</span>
        </p>
        <p id="plan-option-limit-0" className="ml-6 pl-1 text-sm leading-5 md:ml-0 md:pl-0 md:text-right">Custom Programs</p>
      </div>
    </li>
  )
}

export default EquipmentItem;