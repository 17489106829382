import React, { Component } from 'react';
import axios from 'axios';
import ReactPlayer from 'react-player';

import Analytics from '../../Analytics';

import Exercises from '../../components/exercises/Exercises';
import Nav from '../../components/Nav';
// import SettingsNav from '../settings/SettingsNav';
import exerciseData from '../../database/strengthSession';
import SessionMenu from './SessionMenu';

import { Loading } from './data';


class StrengthContainer extends Component {
  
  state = {
    equipmentPackId: 0,
    protocolId: 0,
    sessionId: 1,
    sessionIdx: 0,
    loading: Loading[0],
    selectedExercise: {
      ...exerciseData[0],
      videoUrl: 'https://d2q8dxa4bhce0c.cloudfront.net/exercises/hingeTWY/Hip+hinge+TYW.mp4'
    },
    exercises: []
  }


  componentDidMount() {
    this.loadSession();
  }

  render() {
    return (
      <React.Fragment>
        <Nav />
        <div className="py-10 bg-gray-100">
          <header>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="max-w-3xl mx-auto">
                <h1 className="text-3xl font-bold leading-tight text-gray-900 mb-10">
                  Today's Strength Session
                </h1>
              </div>
            </div>
          </header>
          <main>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="max-w-3xl mx-auto">
                <div style={{ width: '100%', height:'400px', backgroundColor: '#000' }} >
                  {
                    this.state.selectedExercise ? <ReactPlayer volume={0} width='100%' height='400px' url={this.state.selectedExercise.videoUrl} loop={true} playing={true} playsInline={true} /> : null
                  }
                </div>
                <SessionMenu onSelectEquipment={this.selectEquipment} onSelectProtocol={this.selectProtocol} onSelectSession={this.selectSession} onSave={this.getSession} />
                <p className="text-xs text-gray-600 pt-0 pb-10 px-4">{this.state.loading.hint}</p>
                <Exercises selectedExercise={this.state.selectedExercise} onSelect={this.onSelect} data={this.state.exercises} />
              </div>
            </div>
          </main>
        </div>
      </React.Fragment>
    )
  }


  selectEquipment = equipmentPackId => {
    console.log('select equipment: ' + equipmentPackId);
    this.setState({ equipmentPackId });
  }

  selectProtocol = protocolId => {
    console.log('select loading: ' + protocolId);
    this.setState({ protocolId, loading: Loading[protocolId] });
  }

  selectSession = sessionIdx => {
    
    let isOdds = ((sessionIdx + 1)% 2) === 1;
    let sessionId = isOdds ? 1 : 2;
    this.setState({ sessionId, sessionIdx });
    
  }

  getSession = event => {
    event.preventDefault();
    this.loadSession();
  }


  onSelect = selectedExercise => {
    this.setState({ selectedExercise });
    Analytics.playVideo();
  }


  loadSession = () => {

    console.log('load session');

    const { equipmentPackId, sessionId } = this.state;
    let baseUrl = 'https://dev-api.sevenseven.app'; // 'http://localhost:8080';

    let url = `${baseUrl}/sessions/strength?equipmentPackId=${equipmentPackId}&sessionId=${sessionId}`;
    const detail = Loading[this.state.protocolId].loading[this.state.sessionIdx];
    
    axios.get(url)
      .then(response => {
        
        const exercises = response.data.exercises
                            .map(exercise => {
                              return {
                                ...exercise,
                                detail
                              }
                            })
                            .sort((a, b) => a.movementTypeId - b.movementTypeId);
        console.log(exercises);
        this.setState({ exercises , selectedExercise: response.data.exercises[0], isLoading: false });

      })
      .catch(error => console.log(error));
  }

}

export default StrengthContainer;