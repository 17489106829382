import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import axios from 'axios';

import Analytics from '../Analytics';

import Exercises from '../components/exercises/Exercises';
import Nav from '../components/Nav';
import exerciseData from '../database/strengthSession';


class StrengthContainer extends Component {
  
  state = {
    selectedExercise: exerciseData[0],
    isSelected: false,
    filterId: 6,
    exercises: []
  }

  componentDidMount() {
    this.loadDatabse();
  }

  render() {

    var filteredExercises = this.state.exercises;

    if (this.state.filterId < 6) {
      filteredExercises = this.state.exercises.filter(e => e.typeId === this.state.filterId);
    }

    return (
      <React.Fragment>
        <Nav />
        <div className="py-10 bg-gray-100">
          <header>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="max-w-3xl mx-auto">
                <h1 className="text-3xl font-bold leading-tight text-gray-900">
                  Community Database
                </h1>
              </div>
            </div>
          </header>
          <main>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="max-w-3xl mx-auto">
              <span className="relative z-0 inline-flex shadow-sm rounded-md m-6">
                {/* <button type="button" className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500">
                  Years
                </button> */}
                <button onClick={() => this.setState({ filterId: 6 })} type="button" className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500">
                  All
                </button>
                <button onClick={() => this.setState({ filterId: 0 })} type="button" className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500">
                Upper Body
                </button>
                <button onClick={() => this.setState({ filterId: 1 })}  type="button" className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500">
                  Lower Body
                </button>
                <button onClick={() => this.setState({ filterId: 2 })}  type="button" className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500">
                  Core
                </button>
                <button onClick={() => this.setState({ filterId: 3 })} type="button" className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500">
                  Stretch
                </button>
                <button onClick={() => this.setState({ filterId: 4 })} type="button" className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500">
                  Mobility
                </button>
                
              </span>                
                <Exercises isLegend={true} selectedExercise={this.state.selectedExercise} onSelect={this.onSelect} data={filteredExercises} />
                {
                  this.state.isSelected ? this.renderVideo() : null
                }
              </div>
            </div>
          </main>
        </div>
      </React.Fragment>
    )
  }

  renderVideo = () => {
    return (
      <div class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          
          <div class="fixed inset-0 transition-opacity" aria-hidden="true">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          
          <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
            <ReactPlayer volume={0} width='100%' height='400px' url={this.state.selectedExercise.videoUrl} loop={true} playing={true} />
            <button onClick={() => this.setState({ isSelected: false })} type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm">
              Close
            </button>
          </div>
        </div>
      </div>
    )
  }

  onSelect = selectedExercise => {
    this.setState({ selectedExercise, isSelected: true });
    Analytics.playVideo();
  }


  loadDatabse = () => {

    let baseUrl = 'https://dev-api.sevenseven.app';
    let url = `${baseUrl}/exercises/all`;

    axios.get(url)
      .then(response => {
        this.setState({ exercises: response.data });
      })
      .catch(error => console.log(error));

  }


}

export default StrengthContainer;

