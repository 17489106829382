import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import StrengthContainer from '../containers/strength/Strength';

import AppRoutes from '../routes';
import LandingPage from '../landing/LandingPage';
import MinimumMovements from './MinimumMovements';
import Settings from '../containers/settings';
import Login from '../containers/Login';
import Database from '../containers/CommunityDatabase';


const AppContainer  =() => {
  return (
    <React.Fragment>
      <Switch>
        <Route path='/settings' component={Settings} />
        <Route path="/" exact={true} component={LandingPage} />
        <Route path='/minimum-movements' component={MinimumMovements} />
        <Route path='/strength' component={StrengthContainer} />
        <Route path='/settings' component={Settings} />
        <Route path='/login' component={Login} />
        <Route path='/database' component={Database} />
      </Switch>
    </React.Fragment>
  )
}


export default AppContainer;