import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import appRoutes from '../routes';
import imgUrl from '../assets/AppIcon.svg'

const routes = Object.values(appRoutes);




const NavRoute = ({ route }) => {
  const activeStyles = 'inline-flex items-center px-1 pt-1 border-b-2 border-blue-500 text-sm font-medium leading-5 text-gray-900 focus:outline-none focus:border-blue-700 transition duration-150 ease-in-out';
  const inActiveStyles = 'ml-8 inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 transition duration-150 ease-in-out';
  return (
    <NavLink to={route.path} className={inActiveStyles} activeClassName={activeStyles} >
      <div >
        {route.name}
      </div>
    </NavLink>
  )
}

const MobileNavRoute = ({ route }) => {
  const inActiveStyles = 'mt-1 block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out';
  const activeStyles = 'block pl-3 pr-4 py-2 border-l-4 border-blue-500 text-base font-medium text-blue-700 bg-blue-50 focus:outline-none focus:text-blue-800 focus:bg-blue-100 focus:border-blue-700 transition duration-150 ease-in-out';
  return (
    <NavLink to={route.path} className={inActiveStyles} activeClassName={activeStyles} >
      <div>{route.name}</div>
    </NavLink>
  )
}



class Nav extends Component {

  state = {
    isOpen: false
  }

  openMenu = () => {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {

    const mobileOpenClass = this.state.isOpen ? 'block' : 'hidden'

    return (
      <nav className="bg-white shadow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="flex-shrink-0 flex items-center">
              <NavLink to='/' >
                <img className="block lg:hidden h-8 w-auto" src={imgUrl} alt="7/7 logo" />
                <img className="hidden lg:block h-8 w-auto" src={imgUrl} alt="7/7 logo" />
              </NavLink>
              </div>
              <div className="hidden sm:ml-6 sm:flex">
                {
                  routes.map((route, index) => <NavRoute key={index} route={route} />)
                }
              </div>
            </div>
            
            <div className="-mr-2 flex items-center sm:hidden">
              <button onClick={this.openMenu} className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out" aria-label="Main menu" aria-expanded="false">
                
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
                
                <svg className="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        
        <div className={`${mobileOpenClass} sm:${mobileOpenClass}`}>
          <div className="pt-2 pb-3">
            {
              routes.map((route, index) => <MobileNavRoute key={index} route={route} />)
            }
          </div>
        </div>
      </nav>
    )
  }

}



export default Nav;