import React, { Component } from 'react';

import PaymentDetails from './PaymentDetails';
import Plan from './plan';
import BillingHistory from './BillingHistory';
import Nav from '../../components/Nav';
import SettingsNav from './SettingsNav';



class Settings extends Component {

  render() {
    return (
      <div >
        <Nav />
        <main className="max-w-7xl mx-auto pb-10 lg:py-12 lg:px-8 bg-gray-100">
          <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
            
            <SettingsNav />

            <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
              <PaymentDetails />
              <Plan />
              <BillingHistory />
            </div>

          </div>
        </main>
      </div>
      
    )

  }


}



export default Settings;