

const exercises = [
  {
      "_id": "5f97fd3c343a061280cf8dde1111",
      "isLegacy": true,
      "imgUrl": "https://d2q8dxa4bhce0c.cloudfront.net/exercises/hingeTWY/thumbnail.jpg",
      "typeId": 4,
      "videoUrl": "https://d2q8dxa4bhce0c.cloudfront.net/exercises/hingeTWY/Hip+hinge+TYW.mp4",
      "name": "Hinge ‘T, Y, W’",
      "usedAt": 1603796182,
      "__v": 0
  },
  {
      "_id": "5f98009c343a061280cf8dee",
      "imgUrl": "https://d2q8dxa4bhce0c.cloudfront.net/exercises/dumbbellAlternatingBentOverRow/thumbnail.jpg",
      "typeId": 0,
      "videoUrl": "https://d2q8dxa4bhce0c.cloudfront.net/exercises/dumbbellAlternatingBentOverRow/DB+alternating+bent-over+row.mp4",
      "name": "Dumbbell Alternating Bent Over Row",
      "usedAt": 1603797107,
      "isLegacy": true,
      "__v": 0
  },
  {
      "_id": "5f980246343a061280cf8df5",
      "imgUrl": "https://d2q8dxa4bhce0c.cloudfront.net/exercises/body-weight-squat/thumbnail.jpg",
      "typeId": 1,
      "videoUrl": "https://d2q8dxa4bhce0c.cloudfront.net/exercises/body-weight-squat/exercise.mp4",
      "name": "Bodyweight Squats",
      "isLegacy": true,
      "detail": "10 Reps",
      "usedAt": 1603797539,
      "__v": 0
  },
  {
      "_id": "5f980265343a061280cf8df6",
      "imgUrl": "https://d2q8dxa4bhce0c.cloudfront.net/exercises/gobletSquat/thumbnail.jpg",
      "typeId": 1,
      "videoUrl": "https://d2q8dxa4bhce0c.cloudfront.net/exercises/gobletSquat/Goblet+Squat.mp4",
      "name": "Goblet Squat",
      "isLegacy": true,
      "usedAt": 1603797594,
      "__v": 0
  },
  {
      "_id": "5f980528343a061280cf8e09",
      "imgUrl": "https://d2q8dxa4bhce0c.cloudfront.net/exercises/curtseySquat/thumbnail.jpg",
      "typeId": 1,
      "videoUrl": "https://d2q8dxa4bhce0c.cloudfront.net/exercises/curtseySquat/Curtesy+squat.mp4",
      "name": "Curtesy Squat",
      "isLegacy": true,
      "usedAt": 1603798298,
      "__v": 0
  },
  {
      "_id": "5faa27f324dccc688a6fd3de",
      "name": "Pec Stretch",
      "videoUrl": "https://www.youtube.com/watch?v=beu531wsMck",
      "typeId": 3,
      "isLegacy": true,
      "detail": "30 Seconds",
      "__v": 0
  },
  {
      "_id": "5faa281524dccc688a6fd3df",
      "name": "Levator Scapulae/Neck Stretch",
      "videoUrl": "https://www.youtube.com/watch?v=tfKO3Xs-xiY",
      "typeId": 3,
      "isLegacy": true,
      "__v": 0
  },
  {
      "_id": "5faa282b24dccc688a6fd3e0",
      "name": "Lat Stretch",
      "videoUrl": "https://www.youtube.com/watch?v=6AWqyutUgXs",
      "typeId": 3,
      "isLegacy": true,
      "__v": 0
  },
  {
      "_id": "5faa284224dccc688a6fd3e1",
      "name": "Forearm Stretch",
      "videoUrl": "https://www.youtube.com/watch?v=WN7pL46-FwI",
      "typeId": 3,
      "isLegacy": true,
      "__v": 0
  },
  {
      "_id": "5faa285f24dccc688a6fd3e2",
      "name": "Lower Back Stretch",
      "videoUrl": "https://www.youtube.com/watch?v=ifrfG0FAwAg",
      "typeId": 3,
      "isLegacy": true,
      "__v": 0
  },
  {
      "_id": "5faa287c24dccc688a6fd3e3",
      "name": "Hip Flexor and Quad Stretch",
      "videoUrl": "https://www.youtube.com/watch?v=-D0e5MSaEYY",
      "typeId": 3,
      "isLegacy": true,
      "__v": 0
  },
  {
      "_id": "5faa289a24dccc688a6fd3e4",
      "name": "Hamstring Stretch",
      "videoUrl": "https://www.youtube.com/watch?v=mLQ_4U5O6T8",
      "typeId": 3,
      "isLegacy": true,
      "__v": 0
  },
  {
      "_id": "5faa28c824dccc688a6fd3e5",
      "name": "Calf Stretch",
      "videoUrl": "https://www.youtube.com/watch?v=zL9BVT4o6Dw",
      "typeId": 3,
      "isLegacy": true,
      "__v": 0
  },
  {
      "_id": "5faa28d824dccc688a6fd3e6",
      "name": "Glute Stretch",
      "videoUrl": "https://www.youtube.com/watch?v=vtXWZSGddvc",
      "typeId": 3,
      "isLegacy": true,
      "__v": 0
  },
  {
      "_id": "5faa2eab92da846bf5dd93df",
      "name": "Spiderman Pushup",
      "videoUrl": "https://d2q8dxa4bhce0c.cloudfront.net/exercises/spiderman-pushup/exercise.mp4",
      "imgUrl": "https://d2q8dxa4bhce0c.cloudfront.net/exercises/spiderman-pushup/thumbnail.jpg",
      "typeId": 0,
      "isLegacy": true,
      "detail": "10 Reps",
      "__v": 0
  },
  {
      "_id": "5faa2ee192da846bf5dd93e0",
      "name": "Back Widow",
      "videoUrl": "https://www.youtube.com/watch?v=-b3goNU1va0",
      "typeId": 0,
      "isLegacy": true,
      "detail": "10 Reps",
      "__v": 0
  },
  {
      "_id": "5faa2ef392da846bf5dd93e1",
      "name": "Bench Dips",
      "videoUrl": "https://www.youtube.com/watch?v=bU-VAhJnFO0",
      "typeId": 0,
      "isLegacy": true,
      "detail": "10 Reps",
      "__v": 0
  },
  {
      "_id": "5faa2f0492da846bf5dd93e2",
      "name": "Alternating Pushups",
      "videoUrl": "https://www.youtube.com/watch?v=ddYCZpsCuuI",
      "typeId": 0,
      "isLegacy": true,
      "detail": "10 Reps",
      "__v": 0
  },
  {
      "_id": "5faa2f2492da846bf5dd93e3",
      "name": "Towel Back Extension Rows",
      "videoUrl": "https://www.youtube.com/watch?v=wy-wJUpwr2I",
      "typeId": 0,
      "isLegacy": true,
      "detail": "10 Reps",
      "__v": 0
  },
  {
      "_id": "5faa2f4792da846bf5dd93e4",
      "name": "Plank with Shoulder Taps",
      "videoUrl": "https://d2q8dxa4bhce0c.cloudfront.net/exercises/extendedPlankShoulderTaps/Extended+plank+shoulder+taps.mp4",
      "typeId": 0,
      "isLegacy": true,
      "detail": "10 Reps",
      "imgUrl": "https://d2q8dxa4bhce0c.cloudfront.net/exercises/extendedPlankShoulderTaps/thumbnail.jpg",
      "__v": 0
  },
  {
      "_id": "5faa2f5792da846bf5dd93e5",
      "name": "Close Grip Pushups",
      "videoUrl": "https://d2q8dxa4bhce0c.cloudfront.net/exercises/close-grip-push-up/exercise.mp4",
      "imgUrl": "https://d2q8dxa4bhce0c.cloudfront.net/exercises/close-grip-push-up/thumbnail.jpg",
      "typeId": 0,
      "isLegacy": true,
      "detail": "10 Reps",
      "__v": 0
  },
  {
      "_id": "5faa2f7c92da846bf5dd93e6",
      "name": "Shoulder Blade Flutters",
      "videoUrl": "https://www.youtube.com/watch?v=TF_2V8tOtTQ",
      "typeId": 0,
      "isLegacy": true,
      "detail": "10 Reps",
      "__v": 0
  },
  {
      "_id": "5faa2fa792da846bf5dd93e8",
      "name": "Bicep Curl Foot Resisted",
      "videoUrl": "https://www.youtube.com/watch?v=8PoL9hGnHtk",
      "typeId": 0,
      "isLegacy": true,
      "detail": "10 Reps",
      "__v": 0
  },
  {
      "_id": "5faa2fb792da846bf5dd93e9",
      "name": "Door Frame Curl",
      "videoUrl": "https://www.youtube.com/watch?v=PW9QyzI39P0",
      "typeId": 0,
      "isLegacy": true,
      "detail": "10 Reps",
      "__v": 0
  },
  {
      "_id": "5faa2fc792da846bf5dd93ea",
      "name": "Isometric Hammer Curl Hold",
      "videoUrl": "https://www.youtube.com/watch?v=s7Fg2fDaEp8",
      "typeId": 0,
      "isLegacy": true,
      "detail": "10 Reps",
      "__v": 0
  },
  {
      "_id": "5faa2fdc92da846bf5dd93eb",
      "name": "Pushup",
      "videoUrl": "https://d2q8dxa4bhce0c.cloudfront.net/exercises/push-up/exercise.mp4",
      "typeId": 0,
      "imgUrl": "https://d2q8dxa4bhce0c.cloudfront.net/exercises/push-up/thumbnail.jpg",
      "isLegacy": true,
      "detail": "10 Reps",
      "__v": 0
  },
  {
      "_id": "5faa33e392da846bf5dd93ec",
      "name": "Thread the Needle",
      "videoUrl": "https://d2q8dxa4bhce0c.cloudfront.net/exercises/thread-the-needle/exercise.mp4",
      "typeId": 4,
      "imgUrl": "https://d2q8dxa4bhce0c.cloudfront.net/exercises/thread-the-needle/thumbnail.jpg",
      "isLegacy": true,
      "detail": "20 Reps Each Side",
      "__v": 0
  },
  {
      "_id": "5faa33f892da846bf5dd93ed",
      "name": "Archer",
      "videoUrl": "https://www.youtube.com/watch?v=Kl8mbVcj3BE",
      "typeId": 4,
      "isLegacy": true,
      "detail": "20 Reps Each Side",
      "__v": 0
  },
  {
      "_id": "5faa340b92da846bf5dd93ee",
      "name": "Hamstring Mobilise ",
      "videoUrl": "https://www.youtube.com/watch?v=9zHIa5KBJHQ",
      "typeId": 4,
      "isLegacy": true,
      "detail": "20 Reps Each Side",
      "__v": 0
  },
  {
      "_id": "5faa341992da846bf5dd93ef",
      "name": "Groin Mobilise",
      "videoUrl": "https://www.youtube.com/watch?v=E9n36FJYo4g",
      "typeId": 4,
      "isLegacy": true,
      "detail": "20 Reps Each Side",
      "__v": 0
  },
  {
      "_id": "5faa344692da846bf5dd93f0",
      "name": "Frog Squat",
      "videoUrl": "https://www.youtube.com/watch?v=uhQDCdTZnmI",
      "typeId": 1,
      "isLegacy": true,
      "detail": "10 Reps",
      "__v": 0
  },
  {
      "_id": "5faa345492da846bf5dd93f1",
      "name": "Pulse Squats",
      "videoUrl": "https://www.youtube.com/watch?v=rAZBQ5gNejU",
      "typeId": 1,
      "isLegacy": true,
      "detail": "10 Reps",
      "__v": 0
  },
  {
      "_id": "5faa346592da846bf5dd93f2",
      "name": "Step Ups - High Step = Harder",
      "videoUrl": "https://www.youtube.com/watch?v=3A5NgSookz8",
      "typeId": 1,
      "isLegacy": true,
      "detail": "10 Reps",
      "__v": 0
  },
  {
      "_id": "5faa348d92da846bf5dd93f3",
      "name": "Hamstring Heel Curl ",
      "videoUrl": "https://www.youtube.com/watch?v=ofUzHEYzsik",
      "typeId": 1,
      "isLegacy": true,
      "detail": "10 Reps",
      "__v": 0
  },
  {
      "_id": "5faa34a292da846bf5dd93f4",
      "name": "Mountain Climbers",
      "videoUrl": "https://d2q8dxa4bhce0c.cloudfront.net/exercises/mountain-climbers/exercise.mp4",
      "imgUrl": "https://d2q8dxa4bhce0c.cloudfront.net/exercises/mountain-climbers/thumbnail.jpg",
      "typeId": 1,
      "isLegacy": true,
      "detail": "10 Reps",
      "__v": 0
  },
  {
      "_id": "5faf06d4ccee50382766afd3",
      "name": "Explosive Pushup",
      "videoUrl": "https://www.youtube.com/watch?v=3sS8UnXhjw8",
      "typeId": 0,
      "isLegacy": true,
      "detail": "10 Reps",
      "__v": 0
  },
  {
      "_id": "5faf06efccee50382766afd4",
      "name": "Pushup Hold",
      "videoUrl": "https://www.youtube.com/watch?v=VUhcgdkl3kQ",
      "typeId": 0,
      "isLegacy": true,
      "detail": "10 Reps",
      "__v": 0
  },
  {
      "_id": "5faf070bccee50382766afd5",
      "name": "Pike Pushup",
      "videoUrl": "https://d2q8dxa4bhce0c.cloudfront.net/exercises/pike-push-up/exercise.mp4",
      "typeId": 0,
      "imgUrl": "https://d2q8dxa4bhce0c.cloudfront.net/exercises/pike-push-up/thumbnail.jpg",
      "isLegacy": true,
      "detail": "10 Reps",
      "__v": 0
  },
  {
      "_id": "5faf071cccee50382766afd6",
      "name": "Walkouts",
      "videoUrl": "https://d2q8dxa4bhce0c.cloudfront.net/exercises/walkouts/exercise.mp4",
      "imgUrl": "https://d2q8dxa4bhce0c.cloudfront.net/exercises/walkouts/thumbnail.jpg",
      "typeId": 0,
      "isLegacy": true,
      "detail": "10 Reps",
      "__v": 0
  },
  {
      "_id": "5faf072eccee50382766afd7",
      "name": "Hindu Pushup",
      "videoUrl": "https://www.youtube.com/watch?v=SqGN1ztwKCs",
      "typeId": 0,
      "isLegacy": true,
      "detail": "10 Reps",
      "__v": 0
  },
  {
      "_id": "5faf073fccee50382766afd8",
      "name": "Diamond Pushup",
      "videoUrl": "https://www.youtube.com/watch?v=3uQRXRHe-KI",
      "typeId": 0,
      "isLegacy": true,
      "detail": "10 Reps",
      "__v": 0
  },
  {
      "_id": "5faf0752ccee50382766afd9",
      "name": "Bodyweight Tricep Extension",
      "videoUrl": "https://www.youtube.com/watch?v=RG14gvLhZAE",
      "typeId": 0,
      "isLegacy": true,
      "detail": "10 Reps",
      "__v": 0
  },
  {
      "_id": "5faf0762ccee50382766afda",
      "name": "Plank Side Walks",
      "videoUrl": "https://www.youtube.com/watch?v=1cs1WwciHW4",
      "typeId": 0,
      "isLegacy": true,
      "detail": "10 Reps",
      "__v": 0
  },
  {
      "_id": "5faf0775ccee50382766afdb",
      "name": "Half Pushup hold",
      "videoUrl": "https://www.youtube.com/watch?v=DlTcuOAjFU4",
      "typeId": 0,
      "isLegacy": true,
      "detail": "10 Reps",
      "__v": 0
  },
  {
      "_id": "5faf078fccee50382766afdc",
      "name": "Pike Pushup Hold",
      "videoUrl": "https://www.youtube.com/watch?v=rr9vR_wOxdI",
      "typeId": 0,
      "isLegacy": true,
      "detail": "20 Seconds",
      "__v": 0
  },
  {
      "_id": "5faf07a5ccee50382766afdd",
      "name": "Pushup Rotation",
      "videoUrl": "https://d2q8dxa4bhce0c.cloudfront.net/exercises/push-up-rotation/exercise.mp4",
      "imgUrl": "https://d2q8dxa4bhce0c.cloudfront.net/exercises/push-up-rotation/thumbnail.jpg",
      "typeId": 0,
      "isLegacy": true,
      "detail": "10 Reps",
      "__v": 0
  },
  {
      "_id": "5faf07bdccee50382766afde",
      "name": "Half Pushup to Full Pushup",
      "videoUrl": "https://www.youtube.com/watch?v=C6azJA8AJA8",
      "typeId": 0,
      "isLegacy": true,
      "detail": "10 Reps",
      "__v": 0
  },
  {
      "_id": "5faf0878ccee50382766afdf",
      "name": "Knees Over Toes Split Squat",
      "videoUrl": "https://www.youtube.com/watch?v=FDoU0B8hEP4",
      "typeId": 1,
      "isLegacy": true,
      "detail": "10 Reps Each Side",
      "__v": 0
  },
  {
      "_id": "5faf088accee50382766afe0",
      "name": "Calf Raise Walks",
      "videoUrl": "https://www.youtube.com/watch?v=HUKgK3LVaCA",
      "typeId": 1,
      "isLegacy": true,
      "detail": "10 Reps Each Side",
      "__v": 0
  },
  {
      "_id": "5faf08a3ccee50382766afe1",
      "name": "Glute Bridge",
      "videoUrl": "https://d2q8dxa4bhce0c.cloudfront.net/exercises/glute-bridge/exercise.mp4",
      "imgUrl": "https://d2q8dxa4bhce0c.cloudfront.net/exercises/glute-bridge/thumbnail.jpg",
      "typeId": 1,
      "isLegacy": true,
      "detail": "10 Reps",
      "__v": 0
  },
  {
      "_id": "5faf08beccee50382766afe2",
      "name": "Walking Lunges",
      "videoUrl": "https://www.youtube.com/watch?v=iSzVHIj_tsk",
      "typeId": 1,
      "isLegacy": true,
      "detail": "10 Reps Each Side",
      "__v": 0
  },
  {
      "_id": "5faf0916ccee50382766afe4",
      "name": "Sumo Squat",
      "videoUrl": "https://www.youtube.com/watch?v=n_lr_eYWlKQ",
      "typeId": 1,
      "isLegacy": true,
      "detail": "10 Reps",
      "__v": 0
  },
  {
      "_id": "5faf092fccee50382766afe5",
      "name": "Sumo Deadlift",
      "videoUrl": "https://www.youtube.com/watch?v=n-GiVxtUwcw",
      "typeId": 1,
      "isLegacy": true,
      "detail": "10 Reps",
      "__v": 0
  },
  {
      "_id": "5faf094bccee50382766afe6",
      "name": "Jump Squats",
      "videoUrl": "https://www.youtube.com/watch?v=u1Q-8rag194",
      "typeId": 1,
      "isLegacy": true,
      "detail": "10 Reps",
      "__v": 0
  },
  {
      "_id": "5faf0965ccee50382766afe7",
      "name": "Side Lunges",
      "videoUrl": "https://www.youtube.com/watch?v=OtDvwHQj44E",
      "typeId": 1,
      "isLegacy": true,
      "detail": "10 Reps Each Side",
      "__v": 0
  },
  {
      "_id": "5faf0998ccee50382766afe8",
      "name": "Alternating Jump Lunges",
      "videoUrl": "https://www.youtube.com/watch?v=1r1YMSaHzQ0",
      "typeId": 1,
      "isLegacy": true,
      "detail": "10 Reps Each Side",
      "__v": 0
  },
  {
      "_id": "5faf09aeccee50382766afe9",
      "name": "Reverse Lunge and Step Up",
      "videoUrl": "https://www.youtube.com/watch?v=pA1a_ovEwNk",
      "typeId": 1,
      "isLegacy": true,
      "detail": "10 Reps Each Side",
      "__v": 0
  },
  {
      "_id": "5faf09c8ccee50382766afea",
      "name": "Wall Sit",
      "videoUrl": "https://www.youtube.com/watch?v=GD5WF5XsHuI",
      "typeId": 1,
      "isLegacy": true,
      "detail": "30 Seconds",
      "__v": 0
  },
  {
      "_id": "5faf09deccee50382766afeb",
      "name": "Deep Squat Hold",
      "videoUrl": "https://www.youtube.com/watch?v=B1pR3rL2INI",
      "typeId": 1,
      "isLegacy": true,
      "detail": "30 Seconds",
      "__v": 0
  },
  {
      "_id": "5faf09faccee50382766afec",
      "name": "Reverse Lunge",
      "videoUrl": "https://d2q8dxa4bhce0c.cloudfront.net/exercises/reverse-lunge/exercise.mp4",
      "imgUrl": "https://d2q8dxa4bhce0c.cloudfront.net/exercises/reverse-lunge/thumbnail.jpg",
      "typeId": 1,
      "isLegacy": true,
      "detail": "10 Reps Each Side",
      "__v": 0
  },
  {
      "_id": "5faf0a12ccee50382766afed",
      "name": "Clock Lunges",
      "videoUrl": "https://www.youtube.com/watch?v=V_ihqJf6RTQ",
      "typeId": 1,
      "isLegacy": true,
      "detail": "10 Reps Each Side",
      "__v": 0
  },
  {
      "_id": "5faf0a22ccee50382766afee",
      "name": "Single Leg Calf Raise",
      "videoUrl": "https://www.youtube.com/watch?v=3Yu2NOnPAZg",
      "typeId": 1,
      "isLegacy": true,
      "detail": "10 Reps Each Side",
      "__v": 0
  },
  {
      "_id": "5faf0a37ccee50382766afef",
      "name": "Single Leg Glute Bridge",
      "videoUrl": "https://www.youtube.com/watch?v=s7mGlze2gPM",
      "typeId": 1,
      "isLegacy": true,
      "detail": "10 Reps Each Side",
      "__v": 0
  },
  {
      "_id": "5faf0a55ccee50382766aff0",
      "name": "Knees to Feet",
      "videoUrl": "https://www.youtube.com/watch?v=kvfFZf_tHDg",
      "typeId": 1,
      "isLegacy": true,
      "detail": "10 Reps",
      "__v": 0
  },
  {
      "_id": "5faf0a69ccee50382766aff1",
      "name": "Sissy Squat",
      "videoUrl": "https://www.youtube.com/watch?v=_lt5lbTnxRk",
      "typeId": 1,
      "isLegacy": true,
      "detail": "10 Reps",
      "__v": 0
  },
  {
      "_id": "5faf0a7cccee50382766aff2",
      "name": "Step Down",
      "videoUrl": "https://www.youtube.com/watch?v=HVLAgGkddDU",
      "typeId": 1,
      "isLegacy": true,
      "detail": "10 Reps Each Side",
      "__v": 0
  },
  {
      "_id": "5faf0ad0ccee50382766aff3",
      "name": "Plank with Toe Taps",
      "videoUrl": "https://www.youtube.com/watch?v=IeBoPXY4_Ps",
      "typeId": 2,
      "isLegacy": true,
      "detail": "10 Reps Each Side",
      "__v": 0
  },
  {
      "_id": "5faf0ae4ccee50382766aff4",
      "name": "Heel Taps",
      "videoUrl": "https://www.youtube.com/watch?v=LIw00LmKd98",
      "typeId": 2,
      "isLegacy": true,
      "detail": "10 Reps Each Side",
      "__v": 0
  },
  {
      "_id": "5faf0b12ccee50382766aff5",
      "name": "Deadbugs",
      "videoUrl": "https://www.youtube.com/watch?v=Bki2Z7FiiaM",
      "typeId": 2,
      "isLegacy": true,
      "detail": "10 Reps Each Side",
      "__v": 0
  },
  {
      "_id": "5faf0b20ccee50382766aff6",
      "name": "Side Plank Raises",
      "videoUrl": "https://www.youtube.com/watch?v=aILCRug6zaw",
      "typeId": 2,
      "isLegacy": true,
      "detail": "10 Reps Each Side",
      "__v": 0
  },
  {
      "_id": "5faf0b44ccee50382766aff7",
      "name": "Straight Leg Crunch",
      "videoUrl": "https://www.youtube.com/watch?v=OYPn3mSTM5k",
      "typeId": 2,
      "isLegacy": true,
      "detail": "10 Reps",
      "__v": 0
  },
  {
      "_id": "5faf0b59ccee50382766aff8",
      "name": "Twisting Situp",
      "videoUrl": "https://www.youtube.com/watch?v=iOuB6p_drCY",
      "typeId": 2,
      "isLegacy": true,
      "detail": "10 Reps Each Side",
      "__v": 0
  },
  {
      "_id": "5faf0b70ccee50382766aff9",
      "name": "Cross Body Mountain Climbers",
      "videoUrl": "https://www.youtube.com/watch?v=fPpIzKdZ94c",
      "typeId": 2,
      "isLegacy": true,
      "detail": "10 Reps Each Side",
      "__v": 0
  },
  {
      "_id": "5faf0b88ccee50382766affa",
      "name": "Lying Leg Raises",
      "videoUrl": "https://www.youtube.com/watch?v=hTrCYuLGWJM",
      "typeId": 2,
      "isLegacy": true,
      "detail": "10 Reps",
      "__v": 0
  },
  {
      "_id": "5faf0b96ccee50382766affb",
      "name": "Lying Leg Scissors",
      "videoUrl": "https://www.youtube.com/watch?v=WHbOMxzF3sc",
      "typeId": 2,
      "isLegacy": true,
      "detail": "10 Reps",
      "__v": 0
  },
  {
      "_id": "5faf0bafccee50382766affc",
      "name": "Birddog",
      "videoUrl": "https://www.youtube.com/watch?v=EkOjTYQKIKg",
      "typeId": 2,
      "isLegacy": true,
      "detail": "10 Reps Each Side",
      "__v": 0
  },
  {
      "_id": "5faf0bbfccee50382766affd",
      "name": "Windscreen Wipers",
      "videoUrl": "https://www.youtube.com/watch?v=uoVtWw5PqRA",
      "typeId": 2,
      "isLegacy": true,
      "detail": "10 Reps Each Side",
      "__v": 0
  }
];

export default exercises;