
const routes = {
  minimumMovements: {
    name: 'Minimum Movements',
    path: '/minimum-movements'
  },
  strength: {
    name: 'Strength',
    path: '/strength'
  },
  // settings: {
  //   name: 'Settings',
  //   path: '/settings'
  // },
  // database: {
  //   name: 'Database',
  //   path: '/database'
  // }
};;

export default routes;