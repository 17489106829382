import React from 'react';


const PaymentDetails = () => {
  return (
    <section aria-labelledby="payment_details_heading">
      <form action="#" method="POST">
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
            <div>
              <h2 id="payment_details_heading" className="text-lg leading-6 font-medium text-gray-900">Payment details</h2>
              <p className="mt-1 text-sm leading-5 text-gray-500">Update your billing information. Please note that updating your location could affect your tax rates.</p>
            </div>

            <div className="grid grid-cols-4 gap-6">
              <div className="col-span-4 sm:col-span-2">
                <label htmlFor="first_name" className="block text-sm font-medium leading-5 text-gray-700">First name</label>
                <input id="first_name" className="form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
              </div>

              <div className="col-span-4 sm:col-span-2">
                <label htmlFor="last_name" className="block text-sm font-medium leading-5 text-gray-700">Last name</label>
                <input id="last_name" className="form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
              </div>

              <div className="col-span-4 sm:col-span-2">
                <label htmlFor="email_address" className="block text-sm font-medium leading-5 text-gray-700">Email address</label>
                <input id="email_address" className="form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
              </div>

              <div className="col-span-4 sm:col-span-1">
                <label htmlFor="expiration_date" className="block text-sm font-medium leading-5 text-gray-700">Expration date</label>
                <input id="expiration_date" className="form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" placeholder="MM / YY" />
              </div>

              <div className="col-span-4 sm:col-span-1">
                <label htmlFor="security_code" className="flex items-center space-x-1 text-sm font-medium leading-5 text-gray-700">
                  <span>Security code</span>
                  <svg className="flex-shrink-0 h-5 w-5 text-gray-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                  </svg>
                </label>
                <input id="security_code" className="form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
              </div>

              <div className="col-span-4 sm:col-span-2">
                <label htmlFor="country" className="block text-sm font-medium leading-5 text-gray-700">Country / Region</label>
                <select id="country" className="form-select mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                  <option>Australia</option>
                  <option>Canada</option>
                  <option>United States</option>
                </select>
              </div>

              <div className="col-span-4 sm:col-span-2">
                <label htmlFor="postal_code" className="block text-sm font-medium leading-5 text-gray-700">Postal</label>
                <input id="postal_code" className="form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
              </div>
            </div>
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <span className="inline-flex rounded-md shadow-sm">
              <button type="submit" className="bg-gray-800 border border-transparent rounded-md py-2 px-4 inline-flex justify-center text-sm leading-5 font-medium text-white hover:bg-gray-700 focus:outline-none focus:border-gray-900 focus:shadow-outline-gray active:bg-gray-900 transition duration-150 ease-in-out">
                Save
              </button>
            </span>
          </div>
        </div>
      </form>
    </section>
    
  )
}


export default PaymentDetails;