const loading = '10-12 Reps • 3 Sets';
const esLoading = '10-12 Reps Each Side • 3 Sets';

const exercises = [
  {
    "_id": "5faa27f324dccc688a6",
    "name": "Goblet Squat",
    "videoUrl": "https://www.youtube.com/watch?v=beu531wsMck",
    "typeId": 1,
    "isLegacy": true,
    "detail": loading,
    "__v": 0
  },
  {
    "_id": "5faa27f3asdfaeg455jukc688",
    "name": "Romainian Dead List",
    "videoUrl": "https://www.youtube.com/watch?v=beu531wsMck",
    "typeId": 1,
    "isLegacy": true,
    "detail": loading,
    "__v": 0
  },
  {
    "_id": "5faa27f324dccawfh8732465glc",
    "name": "Split Squat",
    "videoUrl": "https://www.youtube.com/watch?v=beu531wsMck",
    "typeId": 1,
    "isLegacy": true,
    "detail": esLoading,
    "__v": 0
  },
  {
    "_id": "5faa27f324dccc6asdhu2i8",
    "name": "Chest Press",
    "videoUrl": "https://www.youtube.com/watch?v=beu531wsMck",
    "typeId": 0,
    "isLegacy": true,
    "detail": loading,
    "__v": 0
  },
  {
    "_id": "5faa27f324dccc688hty90igv4",
    "name": "SA Bent Over Row",
    "videoUrl": "https://www.youtube.com/watch?v=beu531wsMck",
    "typeId": 0,
    "isLegacy": true,
    "detail": esLoading,
    "__v": 0
  },
  {
    "_id": "5faa27f32ccc",
    "name": "Pull Under (Across Body)",
    "videoUrl": "https://www.youtube.com/watch?v=beu531wsMck",
    "typeId": 0,
    "isLegacy": true,
    "detail": esLoading,
    "__v": 0
  },
  {
    "_id": "5f7f324dccc68",
    "name": "Russian Twists",
    "videoUrl": "https://www.youtube.com/watch?v=beu531wsMck",
    "typeId": 2,
    "isLegacy": true,
    "detail": loading,
    "__v": 0
  }
];


export default exercises;