import data from './data';
// import Types from './types';


const getExercise = (type, data) => {
  const exercises = data.filter(exercise => type === exercise.typeId);
  return exercises[Math.floor(Math.random() * exercises.length)]
};


const mob1 = getExercise(4, data);
const mobData = data.filter(ex => ex._id !== mob1._id);
const mob2 = getExercise(4, mobData);

const upper1 = getExercise(0, data);
const upperData = data.filter(ex => ex._id !== upper1._id);
const upper2 = getExercise(0, upperData);

const lower1 = getExercise(1, data);
const lower1Data = data.filter(ex => ex._id !== lower1._id);
const lower2 = getExercise(1, lower1Data);
const lower2Data = data.filter(ex => ex._id !== lower1._id && ex._id !== lower2._id);
const lower3 = getExercise(1, lower2Data);

const exercises = [
  mob1,
  mob2,
  upper1,
  upper2,
  lower1,
  lower2,
  lower3
];


export default exercises;