import React from 'react';
import ExerciseTypes from './types';


const VideoIcons = ({ url, thumbnail, isLegacy }) => {
  let inactive = 'text-gray-400';
  let active = 'text-green-600';
  const newUrl = url || '';

  const isYoutube = newUrl.includes('youtube');
  const isCloud = newUrl.includes('cloudfront');
  const isThumbnail = thumbnail || false;
  
  return (
    <div className="flex justify-center space-x-6 md:order-2">
      <div target="_blank" className={ isYoutube ? active : inactive }>
        Youtube
      </div>
      <div target="_blank" className={ isCloud ? active : inactive }>
        7/7 Cloud
      </div>
      <div target="_blank" className={ isThumbnail ? active : inactive } >
        Thumbnail
      </div>
      {
        isLegacy ? <div target="_blank" className='text-green-600' >
        Legacy
      </div> : null
      }
      
    </div>
  )
}


const Exercise = ({ exercise, onSelect, isSelected, isLegend }) => {
  const textColour = isSelected ? 'text-blue-600' : 'text-gray-700';

  return (
    <a onClick={() => onSelect(exercise)} className="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
      <div className="px-4 py-4 sm:px-6">
        <div className="flex items-center justify-between">
          <div className={`text-sm leading-5 font-medium ${textColour} truncate`}>
            {exercise.name}
          </div>
          <div className="ml-2 flex-shrink-0 flex">
            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
              {ExerciseTypes[exercise.typeId]}
            </span>
          </div>
        </div>
        <div className="mt-2 sm:flex sm:justify-between">
          <div className="sm:flex">
            <div className="mr-6 flex items-center text-sm leading-5 text-gray-500">
              {
                isLegend ? <VideoIcons url={exercise.videoUrl} thumbnail={exercise.imgUrl} isLegacy={exercise.isLegacy} /> : exercise.detail || '12 Reps'
              }
            </div>
          </div>
          
        </div>
      </div>
    </a>
  )
}


export default Exercise;