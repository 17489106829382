import React from 'react';
import EquipmentItem from './EquipmentItem';

const packs = ['Bands', 'Dumbbells', 'Kettle Bells', 'Total Strength'];


const Plan = () => {
  return (
    <section aria-labelledby="plan_heading">
      <form action="#" method="POST">
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
            <div>
              <h2 id="plan_heading" className="text-lg leading-6 font-medium text-gray-900">Equipment Pack</h2>
            </div>

            <fieldset>
              <legend className="sr-only">
                Pricing plans
              </legend>
              <ul className="relative bg-white rounded-md -space-y-px">
                {
                  packs.map((pack, idx) => <EquipmentItem key={idx} name={pack} />)
                }
              </ul>
            </fieldset>

            <div className="flex items-center space-x-3">
              <span role="checkbox" tabIndex="0" aria-checked="true" aria-labelledby="toggleLabel" className="bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline-blue">
                <span aria-hidden="true" className="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200"></span>
              </span>
              <span id="toggleLabel">
                <span className="text-sm leading-5 font-medium text-gray-900">Annual billing </span>
                <span className="text-sm leading-5 text-gray-500">(Save 10%)</span>
              </span>
            </div>
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <span className="inline-flex rounded-md shadow-sm">
              <button type="submit" className="bg-gray-800 border border-transparent rounded-md py-2 px-4 inline-flex justify-center text-sm leading-5 font-medium text-white hover:bg-gray-700 focus:outline-none focus:border-gray-900 focus:shadow-outline-gray active:bg-gray-900 transition duration-150 ease-in-out">
                Save
              </button>
            </span>
          </div>
        </div>
      </form>
    </section>
  )
}



export default Plan;