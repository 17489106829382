import React, { Component } from 'react';
import Analytics from '../Analytics';
import Hero from './Hero';
import Features from './features/Features';
import CTA from './cta/CTA';
import Testimonial from './Testimonial';
import Footer from './Footer';


class LandingPage extends Component {

  componentDidMount() {
    Analytics.landingPageOpen();
  }

  render() {
    return (
      <React.Fragment>
        <Hero />
        <Features />
        <CTA />
        <Testimonial />
        <Footer />
      </React.Fragment>
    )
  }
}

export default LandingPage;