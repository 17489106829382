import React from 'react';

import { EquipmentType, ProtocolType, sessions } from './data';


const SessionMenu = ({ onSelectEquipment, onSelectProtocol, onSelectSession, onSave }) => {
  return (
    <div className="mt-10 mb-10 sm:mt-0">
      <div className="md:grid md:grid-cols-3 md:gap-6">
        
        <div className="mt-5 md:mt-0 md:col-span-12">
          <form action="#" method="POST">
            <div className="shadow overflow-hidden sm:rounded-md">
              <div className="px-4 py-5 bg-white sm:p-6">
                <div className="grid grid-cols-12 gap-6">
                  
                  <div className="col-span-4 sm:col-span-4">
                    <label htmlFor="country" className="block text-sm font-medium text-gray-700">Equipment</label>
                    <select onChange={event => onSelectEquipment(event.target.selectedIndex)} id="country" name="country" autoComplete="country" className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                      {
                        Object.values(EquipmentType).map((equipment, idx) => {
                          return <option key={idx} >{equipment}</option>
                        })
                      }
                    </select>
                  </div>

                  <div className="col-span-4 sm:col-span-4">
                    <label htmlFor="country" className="block text-sm font-medium text-gray-700">Style</label>
                    <select onChange={event => onSelectProtocol(event.target.selectedIndex)} id="country" name="country" autoComplete="country" className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                      {
                        Object.values(ProtocolType).map((type, idx) => {
                          return <option key={idx}>{type}</option>
                        })
                      }
                    </select>
                  </div>

                  <div className="col-span-4 sm:col-span-4">
                    <label htmlFor="country" className="block text-sm font-medium text-gray-700">Session</label>
                    <select onChange={event => onSelectSession(event.target.selectedIndex)} id="country" name="country" autoComplete="country" className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                      {
                        sessions.map((session, idx) => {
                          return <option id={idx} key={idx}>Session {idx + 1}</option>
                        })
                      }
                    </select>
                  </div>

                </div>
              </div>
              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button onClick={event => onSave(event)} type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                  Load
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}



export default SessionMenu;