import React, { Component } from 'react';
import axios from 'axios';

import SuccessMessage from './SubscriberSuccess';

class SubscriberForm extends Component {

  state = {
    isSubscribed: false,
    isExistingSubscriber: false,
    email: ''
  }

  handleChange = (event) => {
    this.setState({ email: event.target.value });
  }

  handleSubmit(event) {
    const { email } = this.state;

    if (email) {
      event.preventDefault();
      if (this.validateEmail(email)) {
        this.uploadSubscriber(email);
      } else {
        alert(`Not valid: ${this.state.email}`);
      }      
    } 
    
  }


  validateEmail = email => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }



  render() {
    return (
      <div className="mt-8 sm:w-full sm:max-w-md xl:mt-0 xl:ml-8">
        {
          this.state.isSubscribed ? this.renderSuccessMessage() : this.renderForm()
        }
      </div>
    )
  }


  renderForm = () => {
    return (
      <React.Fragment>
        <form className="sm:flex" aria-labelledby="newsletter-headline">

          <input aria-label="Email address" type="email" required className="appearance-none w-full px-5 py-3 border border-transparent text-base leading-6 rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 transition duration-150 ease-in-out" 
            value={this.state.name}
            onChange={event => this.handleChange(event)}
            placeholder="Enter your email" />

          <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
            <button onClick={this.handleSubmit.bind(this)} className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-400 focus:outline-none focus:bg-blue-400 transition duration-150 ease-in-out">
              {this.props.buttonTitle}
            </button>
          </div>
        </form>

        <p className="mt-3 text-sm leading-5 text-blue-200">
          {this.props.caption}
        </p>

      </React.Fragment>
    )
  }



  renderSuccessMessage = () => {
    const existingSubscriberMessage = 'Already a subscriber!';
    console.log(this.state);
    const title = this.state.isExistingSubscriber ? existingSubscriberMessage : this.props.successMessage;
    return <SuccessMessage title={title} onClose={this.toggleSubscribe} />
  }

  toggleSubscribe = () => {
    this.setState({ isSubscribed: !this.state.isSubscribed, isExistingSubscriber: false })
  }

  
  uploadSubscriber = email => {
    const baseUrl = 'http://77api-env.eba-amqtiiv6.ap-southeast-2.elasticbeanstalk.com';
    const url = `${baseUrl}/subscribers/add`;
    axios.post(url, {
      email
    })
    .then((response) => {
      
      const { status } = response;

      if (status == 200) {
        this.toggleSubscribe();
      }
      
      if (status == 201) {
        this.setState({ isExistingSubscriber: true, isSubscribed: true })
      }

    })
    .catch((error) => {
      console.log(error);
      alert('Error subscribing. Please try again');
    });
  }


}


export default SubscriberForm;